import * as React from 'react'
import Nav from "../components/nav";
import Layout from "../components/layout"
import HeroImage from "../images/hero-image.webp"
import HeroImageM from "../images/hero-image - m.webp"
import AreasWeHelp from "../components/AreasWeHelp";
import Arrow from "../images/arrow-right-white.svg"
import WorkExamples from "../components/work-examples";
import AboutUs from "../components/About-us";
import Footer from "../components/footer";
import YoutubeThumb from "../images/youtube-thumb.webp"
import WorkExamplesNew from "../components/work-examples-new";
import SocialMedia from "../components/social-media";
import Reviews from "../components/reviews";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import UpdatedReviews from "../components/updated-reviews";
import {useEffect} from "react";
import {Helmet} from "react-helmet";
import WorkExamplesLast from "../components/work-examples-last";
import {useCollapse} from "react-collapsed";

//npx gatsby develop
function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"text-lg font-bold p-1 flex justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }

            </h2>
            <section  {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

export default function FrequentlyAskedQuestions() {


    return (
        <Layout>
            <Helmet>
                <title>Kinetic Impact | Frequently Asked Questions</title>

            </Helmet>
            <main>
                <Nav/>

                <div className={"lg:py-20 py-10 bg-white"}>
                    <div>
                        <h1 className={"lg:text-7xl text-5xl text-center"}>Frequently Asked Questions</h1>
                    </div>
                    <div className={"mx-auto max-w-2xl pt-10 lg:pt-20 px-2"}>
                    <Collapsible title={"How long do your sessions typically last?"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Your first visit will all be 1-hour long and follow up sessions range between 1 hour and 30 minutes based on what you and one of the clinician feel is necessary. </p>
                        </div>
                    </Collapsible>
                        <Collapsible title={"Will I be treated during our first session together?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Yes, we believe that people should always leave with a plan the first day on how to manage their symptoms along with progressing towards their goals. </p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"How is your clinic different from other companies?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 max-w-5xl px-4 mx-auto"}>Our approach is holistic and all-inclusive because we know that optimizing movement in order to relieve you of pain and dysfunction requires an approach that takes everything into consideration.</p>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>You will leave with an exact plan of how it relates to your story and how you will progress to your goals. Your whole session will be spent one-on-one with a physical therapist or chiropractor instead of on your own or with someone who is not qualified to guide you through the process.</p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"How many sessions will I need?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>After your initial assessment, your clinician will provide you with a recommendation based on your needs. The majority of clients feel better, move better, and are ready to perform within 10 sessions. </p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"Do you offer virtual sessions?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Yes, you will receive the same quality of care and training from the comfort of your own home.</p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"What do I need to bring for my first session?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>We are movement based so we require you to bring any sort of clothing that you feel comfortable moving in. </p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"What sort of training does your therapist have?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>All of our physical therapists and chiropractors have obtained doctorate level education and are certified as trainers or strength coaches. Everyone has taken numerous courses, continuing education, and have been mentored by some of the industry elites.</p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"Can you/do you work with [insert] injury or diagnosis?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Our physical therapists and chiropractors have thousands of sessions under their belt and have lots of experience with movement disorders. We are confident that we can help you with anything as it relates to musculoskeletal care. If you have any questions, please contact us so we can help.</p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"Does your company take HSA/FSA?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>We do accept HSA/FSA of all kinds for all of our services. Our administrative team can provide you with any receipts or additional documentation upon request. </p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"Does your company take insurance?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>We are an out-of-network provider so we do not take health insurance <span className={"underline font-bold"}>BUT</span> we do provide superbills for people with out-of-network benefits. You will pay at the time of service and you provide the superbill to your insurance provider who will then reimburse you (amount depending upon your policy). Make sure you speak with your provider and ask if your policy covers out-of-network chiropractic or physical therapy and to what extent. </p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"Does your company verify my insurance benefits before coming in?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>We do not verify benefits. We do not work with insurance directly but we can give you a superbill to get reimbursed by your insurance policy.</p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"What is a superbill?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>A superbill is a medical receipt that we will provide you when you pay upfront with us after your session. You can use this receipt as a way to get reimbursed directly through your insurance depending on your benefits.</p>
                            </div>
                        </Collapsible>
                        <Collapsible title={"What is your cancellation or rescheduling policy?"}>
                            <div className={"flex flex-col justify-center"}>
                                <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>If you cancel or reschedule, we require a 24 hours advance notice. If you cancel or reschedule within 24 hours, you will be charged for the session.  </p>
                            </div>
                        </Collapsible>
                    </div>


                </div>



                <Footer/>

            </main>
        </Layout>
    )
}